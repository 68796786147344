import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import Beranda from './Beranda';
import DetailProject from './DetailProject';
import Project from './Project';


function App() {
  return (
    <BrowserRouter>

        <Routes>
          <Route path="/"  element={<Beranda />} />
          <Route path="/Portofolio" element={<Project />} />
            {/* Pagination */}
            <Route path="/Portofolio/page/:pageNumber" element={<Project />}/> 
          <Route path="/DetailProject/:id" element={<DetailProject />} />
        </Routes>

    </BrowserRouter>
  );
}

export default App;
