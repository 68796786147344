import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParams } from 'react-router-dom';
import './assets/css/style.css';

import axios from 'axios';
function DetailProject() {
    const { id } = useParams();

    useEffect(() => {
        document.title = "MerapiKoding | Detail Project";
        getPosting();
    }, []);

    const [posting, setPosting] = useState([]);
    const [gambarDeskripsi, setGambarDeskripsi] = useState([]);
    // postingLanding/31

    const getPosting = async () => {
        const response = await axios.get(`postingLanding/${id}`);
        if (response.data.length) {
          setPosting(response.data[0]);
          setGambarDeskripsi(response.data[0].deskripsi_gambar);
          // setTotal(Math.ceil(response.data[1].jumlah / limit));
        }
    }

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const FormatTanggal = (waktu) => {
        const dateObject = new Date(waktu.timestamp);
        // Get day, month, and year
        const day = dateObject.getDate();
        const monthIndex = dateObject.getMonth();
        const monthName = monthNames[monthIndex];
        const year = dateObject.getFullYear();

        return `${day} ${monthName} ${year}`;

    }

    return (
        <div>
            <main id="main" className="flex-shrink-0" style={{ minHeight: "100vh" }}>
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Portfolio Details</h2>
                            <ol>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>Portfolio Details</li>
                            </ol>
                        </div>
                    </div>
                </section>
                {/* End Breadcrumbs */}
                {/* ======= Portfolio Details Section ======= */}
                <section id="portfolio-details" className="portfolio-details">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-8">
                            <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            navigation={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {!!posting.gambar && posting.gambar ? (
                                posting.gambar.map((item, i) => (
                                    <SwiperSlide className='d-flex flex-column align-items-center'>
                                        <div style={{ position: 'relative' }}>
                                            <img src={'https://backend.merapikoding.com/assets/image/' + item} height={500} alt="" />
                                            <p style={{ width: '500px',marginBottom:'50px' }} ><i>{gambarDeskripsi[i]}</i></p>
                                        </div>
                                    </SwiperSlide>
                                ))) : (null)
                            }
                        </Swiper>

                            </div>
                            <div className="col-lg-4">
                                <div className="portfolio-info">
                                    <h3>Project information</h3>
                                    <ul>
                                        <li>
                                            <strong>Aplication Name</strong> : {posting.nama}
                                        </li>
                                        <li>
                                            <strong>Category</strong> : {posting.jenis}
                                        </li>
                                        <li>
                                            <strong>Client</strong> : {posting.client}
                                        </li>
                                        <li>
                                            {/* convert date 2024-04-07T05:23:11.000Z to 2024-04-07 */}

                                            <strong>Date Project</strong> : {posting.date ? (<FormatTanggal timestamp={posting.date} />) : ("-")}
                                        </li>

                                        <li>
                                            <a className="btn btn-primary px-4 py-2" type='button' href={'https://backend.merapikoding.com/dokumentasi/' + posting.dokumen} download={posting.dokumen}>
                                                <div className="d-inline-block bi bi-download me-2" />
                                                Download Documentation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="portfolio-description">
                                <div>
                                    <h2>Detail Information</h2>
                                    <p>{posting.keterangan}</p>
                                </div>
                                <div>
                                    <h4>Fitur</h4>
                                    <ul>
                                        {!!posting.fitur && posting.fitur ? (
                                            posting.fitur.map((fitur, i) => (
                                                <li>{fitur}</li>
                                            ))) : (null)
                                        }
                                    </ul>
                                </div>
                                <div>
                                    <h4>Tools</h4>
                                    <ul className='simbol'>
                                        {!!posting.framework && posting.framework ? (
                                            posting.framework.map((framework, i) => (
                                                <li>{framework} </li>
                                            ))) : (null)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Portfolio Details Section */}
            </main>
            {/* End #main */}
            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="container footer-bottom clearfix">
                    <div className="copyright">
                        © Copyright{" "}
                        <strong>
                            <span>Merapi Koding</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by <a href="https://merapikoding.com/">MerapiKoding</a>
                    </div>
                </div>
            </footer>
            {/* End Footer */}
        </div>
    );
}

export default DetailProject;
