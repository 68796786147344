import React, { useEffect, useState } from 'react';
import { project } from './data';
import axios from 'axios';



function Beranda() {

  const [posting, setPosting] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [activeSection, setActiveSection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 6; // jumlah item per halaman

  useEffect(() => {
    document.title = "Merapi Koding | Beranda";
    getPosting();
    visitor();
  }, []);

  const getPosting = async () => {
    const response = await axios.get(`/landingpost?page=${currentPage}&limit=${limit}`);
    if (response.data.length) {
      setPosting(response.data);
      // setTotal(Math.ceil(response.data[1].jumlah / limit));
      // console.log(response.data);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const scrollPosition = window.scrollY;

      sections.forEach(section => {
        const top = section.offsetTop;
        const height = section.offsetHeight;

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const visitor = async () => {
    try {
      let cachedResponse = localStorage.getItem('visitorData');
      if (cachedResponse) {
        // Use cached data if available
        setPosting(JSON.parse(cachedResponse));
      } else {
        // Fetch fresh data and store in cache
        const response = await axios.get('/visitor/process');
        setPosting(response.data);
        localStorage.setItem('visitorData', JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error while making visitor request:", error);
    }
  }

  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <h3 className="logo">
            <a href="/" className="logo"><img src={require('./assets/img/logo.jpeg')} alt="" class="img-fluid" /> Merapi Koding</a>
          </h3>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className={activeSection === 'hero' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className={activeSection === 'services' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="#services">
                  Services
                </a>
              </li>
              <li>

                <a className={activeSection === 'portfolio' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="#portfolio">

                  Portfolio
                </a>
              </li>
              <li>
                <a className={activeSection === 'team' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="#team">
                  Team
                </a>
              </li>
              <li>
                <a className={activeSection === 'contact' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Unlock Your Business Potential!</h1>
              <h2>Transformasikan cara Anda mengelola bisnis dengan sempurna bersama MerapiKoding! Dapatkan kontrol penuh, efisiensi maksimal, dan wawasan mendalam tentang kesehatan keuangan perusahaan Anda dengan software ERP terintegrasi.</h2>
              <div className="d-flex">
                <a href="#about" className="btn-get-started scrollto">
                  Get Started
                </a>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img style={{ borderRadius: "10px" }} src={require('./assets/img/1337527.png')} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
      <main id="main">
        {/* ======= Services Section ======= */}
        <section id="services" className="services section-bg mt-5">
          <div className="container">
            <div className="section-title">
              <span>Services</span>
              <h2>Services</h2>
              <p>
                Sit sint consectetur velit quisquam cupiditate impedit suscipit
                alias
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-industry" />
                  </div>
                  <h4>
                    <a href="/#">Software Manufaktur</a>
                  </h4>
                  <p>Software manufaktur membantu Anda mengelola lini produksi dari mulai pembuatan bill of material (BoM) untuk pesanan, batch number produk, penetapan harga produksi, assembly dan disassembly, dan lain-lain.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-warehouse" />
                  </div>
                  <h4>
                    <a href="/#">Software Distributor</a>
                  </h4>
                  <p>Software ERP distributor membantu Anda mengelola manajemen pembelian dan penjualan menjadi lebih rapi, nilai persediaan barang yang lebih akurat, operasional gudang yang lebih efisien, penagihan utang dan piutang, dan lain-lain.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-snowplow" />
                  </div>
                  <h4>
                    <a href="/#">Software Kontraktor</a>
                  </h4>
                  <p>Software kontraktor membantu Anda memperoleh laba besar dengan membuat budgeting di awal, membandingkan dengan biaya aktual, memiliki laporan laba rugi tiap proyek, mengontrol stok barang proyek, dan lain-lain.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-world" />
                  </div>
                  <h4>
                    <a href="/#">Software IoT</a>
                  </h4>
                  <p>Software kontraktor membantu Anda memantau dan mengendalikan perangkat secara jarak jauh melalui koneksi internet. Ini memungkinkan pengguna untuk mengakses dan mengontrol perangkat IoT mereka dari mana saja, kapan saja.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-school" />
                  </div>
                  <h4>
                    <a href="/#">Software Akademik</a>
                  </h4>
                  <p>Software akademik menyediakan solusi lengkap untuk mengelola pendidikan, termasuk administrasi siswa, pembuatan jadwal, pencatatan absensi, dan analisis kinerja. Dengan fitur-fitur ini, efisiensi operasional institusi pendidikan dapat ditingkatkan secara signifikan.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon">
                    <i className="fas fa-mobile-alt" />
                  </div>
                  <h4>
                    <a href="/#">Mobile App</a>
                  </h4>
                  <p>Mobile App membantu Anda mengelola berbagai aspek dari mana saja dan kapan saja, selama mereka memiliki koneksi internet atau menyimpan data secara offline. Ini memberikan fleksibilitas dan kenyamanan dalam menggunakan layanan dan informasi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Portfolio Section ======= */}
        <section id="portfolio" className="portfolio mt-5">
          <div className="container" style={{ marginTop: '50px' }}>
            <div className="section-title">
              <span>Portfolio</span>
              <h2>Portfolio</h2>
              <p>
                berikut hasil project dan portfolio yang kami pernah buat :
              </p>
            </div>

            <div className='row'>
              {!!posting && Array.isArray(posting) && posting.length ? (
                posting.map((item, i) => (
                  <div key={i} className="col-lg-4 col-md-6 portfolio-item mt-2">
                    <div className="card">
                      <img src={'https://backend.merapikoding.com/assets/image/' + item.gambar[0]} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="fw-bolder text-center">{item.nama}</h5>
                        <p style={{ textAlign: 'justify' }} className="card-text">{`${item.keterangan.slice(0, 120)}...`}</p>
                        <a href={"/DetailProject/" + item.id} className="btn btn-primary">Detail</a>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>Tidak ada data</div>
              )}
              <div class="d-grid gap-2 col-6 mx-auto">
                <a className="btn btn-outline-success" href="/Portofolio">Lihat Semua</a>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Section */}
        {/* ======= Cta Section ======= */}
        <section id="cta" className="cta">
          <div className="container">
            <div className="text-center">
              <h3>Hubungi Kami!</h3>
              <p>
                Silakan hubungi kami untuk mengirimkan pertanyaan, masukan, atau permintaan layanan kepada tim kami. 
                Kami akan berusaha untuk merespons secepat mungkin. Terima kasih atas minat dan dukungan Anda kepada kami. Kami berkomitmen untuk memberikan layanan terbaik kepada Anda.
              </p>
              <a className="cta-btn" href="#contact">contact</a>
            </div>
          </div>
        </section>
        {/* End Cta Section */}
        {/* ======= Team Section ======= */}
        <section id="team" className="team section-bg" >
          <div className="container" style={{ marginTop: '50px' }}>
            <div className="section-title">
              <span>Team</span>
              <h2>Team</h2>
              <p>bergabung bersama kami</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src={require('./assets/img/team/rival.png')} alt="" />
                  <h4>Rival Dwiky Indrawan</h4>
                  <span>CEO</span>
                  <p>Seorang Programmer yang mampu melakukan pemrograman di bidang Android dan Web, dan telah memiliki pengalaman lebih dari 2 tahun dalam membuat aplikasi berbasis web maupun android serta berpengalaman dalam pembuatan sistem berbasis IoT (Internet of Things).</p>
                  <div className="social">
                    <a href="https://rival.merapikoding.com/">
                      <i className="bx bx-world" />
                    </a>
                    <a href="https://www.instagram.com/rival_d21/">
                      <i className="bi bi-instagram" />
                    </a>
                    <a href="https://www.linkedin.com/in/rivaldwiky/">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src={require('./assets/img/team/ghozi.jpeg')} alt="" />
                  <h4>Ghozi Fadilah Himma</h4>
                  <span>CTO</span>
                  <p>Saya seorang fullstack web developer yang telah memiliki pengalaman dalam membangun berbagai jenis aplikasi web. Selain itu, saya juga memiliki keterampilan dalam desain grafis, seperti membuat layout website, desain logo, dan desain grafis lainnya.</p>
                  <div className="social">
                    <a href="https://ghozi.merapikoding.com/">
                      <i className="bx bx-world" />
                    </a>
                    <a href="https://www.instagram.com/ghozifadhilah/">
                      <i className="bi bi-instagram" />
                    </a>
                    <a href="https://www.linkedin.com/in/ghozi-fadhillah-himma-3178b9188/">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src={require('./assets/img/team/pingky.png')} alt="" />
                  <h4>Pingky Ayu Lestari</h4>
                  <span>HRD</span>
                  <p>Seorang HRD dengan pengalaman satu tahun telah mengembangkan pemahaman dasar dalam manajemen sumber daya manusia, terlibat dalam proses rekrutmen dan seleksi, menyusun program pelatihan, dan melakukan administrasi personalia.</p>
                  <div className="social">
                    <a href="https://pingky.merapikoding.com/">
                      <i className="bx bx-world" />
                    </a>
                    <a href="https://www.instagram.com/piinkyyaa/">
                      <i className="bi bi-instagram" />
                    </a>
                    <a href="https://www.linkedin.com/in/pingkyayu/">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Team Section */}
        {/* ======= Contact Section ======= */}
        <section id="contact" className="py-5 contact mt-5">
          <div className="container" style={{ marginTop: '50px' }}>
            {/* Contact form*/}
            <div className="py-5">
              <div className="text-center mb-5">
                <h1 className="fw-bolder">Hubungi Kami</h1>
                <p className="lead fw-normal text-muted mb-0">Let's work together!</p>
              </div>
              <div className="row gx-5 justify-content-center">
                <div className="col-lg-6 ">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d247.07879964312554!2d110.42925198431666!3d-7.762142958015496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5bb1c0fdb4cb%3A0x4a1fe7b1724b56a5!2sJl.%20Satria%20II%20No.199%2C%20Denokan%2C%20Maguwoharjo%2C%20Kec.%20Depok%2C%20Kabupaten%20Sleman%2C%20Daerah%20Istimewa%20Yogyakarta%2055282!5e0!3m2!1sid!2sid!4v1667652692789!5m2!1sid!2sid" width="100%" height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
                <div className="col-lg-6">
                  <div>
                    <h5><i className="bx bx-envelope" /> Email</h5>
                    <p>merapikoding@gmail.com</p>
                  </div>
                  <div>
                    <h5><i className="bx bx-phone-call" /> Telepon</h5>
                    <p>+62 889 7018 6806</p>
                  </div>
                  <div>
                    <h5><i className="bx bx-map" /> Alamat</h5>
                    <p>Jl. Satria II No.199, Denokan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </main>
      {/* End #main */}
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Merapi Koding</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://merapikoding.com/">MerapiKoding</a>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
}

export default Beranda;
